/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import DefaultLayout from '../containers/default';
import { colors, device } from '../styles/globals';
import MainWrap from '../components/layout/wrap';
import {
  DocumentTitle,
  Paragraph,
} from '../components/legal-formatting';

const Button = styled.button`
  padding: 12px 24px;
  text-transform: uppercase;
  background-color: ${colors.darkGrey};
  color: ${colors.white};
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  &:hover {
    cursor: pointer;
    background-color: ${colors.blue};
  }

  @media ${device.tablet} {
    font-size: 16px;
    width: 50%;
    align-self: center;
  }

  @media ${device.tabletS} {
    width: 100%;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export default () => (
  <DefaultLayout noLogin>
    <Helmet>
      <title>all.health 404</title>
      <meta name="description" content="all.health 404" />
    </Helmet>
    <MainWrap>
      <DocumentTitle>The page cannot be found</DocumentTitle>
      <Paragraph>
        The page you are looking for was moved, removed, renamed or might never existed.
      </Paragraph>
      <Button onClick={() => navigate('/')}>
        go home
      </Button>
    </MainWrap>
  </DefaultLayout>
);
